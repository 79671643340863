var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.$t(_vm.translationKeys.tooltipMsg),
    placement: _vm.placement,
    offset: _vm.offset,
    delay: {
      show: _vm.delay.show,
      hide: _vm.delay.hide,
    },
  }),expression:"{\n    content: $t(translationKeys.tooltipMsg),\n    placement: placement,\n    offset: offset,\n    delay: {\n      show: delay.show,\n      hide: delay.hide,\n    },\n  }"}],staticClass:"apptooltip"},[_c('img',{attrs:{"src":"/imgs/ask-tooltip.svg","alt":"icono de consejo"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }